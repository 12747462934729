import 'modern-normalize'
import 'typeface-nunito'
import 'typeface-open-sans'

import 'styles/index.less'

async function core() {
  document.documentElement.classList.remove('no-js')
}

core()
